import * as React from 'react'
import { useTheme } from '@features/theme/theme.hooks'
import {
  type Participant,
  Track,
  type LocalAudioTrack,
  type RemoteAudioTrack,
  RemoteVideoTrack,
  LocalVideoTrack,
} from 'livekit-client'
import {
  type ParticipantClickEvent,
  type TrackReferenceOrPlaceholder,
  isTrackReference,
  isTrackReferencePinned,
} from '@livekit/components-core'
import {
  ConnectionQualityIndicator,
  // ParticipantName,
  TrackMutedIndicator,
  ParticipantContext,
  TrackRefContext,
  useEnsureTrackRef,
  useFeatureContext,
  useMaybeLayoutContext,
  FocusToggle,
  useMaybeParticipantContext,
  VideoTrack,
  AudioTrack,
  useParticipantTile,
  ScreenShareIcon,
  useMaybeTrackRefContext,
  useIsSpeaking,
  ParticipantNameProps,
  useEnsureParticipant,
  useAudioWaveform,
  useRemoteParticipants,
} from '@livekit/components-react'
import { Avatar, Box, Button, Modal, Stack, Tooltip, Typography } from '@common/components'
import { useCurrentEvent } from '@features/event/hooks'
import { handleGravatar } from '@common/utils/gravatar'
import { IconContainer, NameContainer } from '../video-conference.styles'
import {
  CancelOutlinedIcon,
  FlagIcon,
  KeyboardArrowDownIcon,
  LoopIcon,
  MicNoneOutlinedIcon,
  MicOffOutlinedIcon,
  Person2OutlinedIcon,
  PersonAddAltIcon,
  RecordVoiceOverOutlinedIcon,
  ShieldIcon,
  VideocamOffOutlinedIcon,
  VideocamOutlinedIcon,
} from '@common/icons'
import UpdateAccountForm from '@features/auth/components/update-account-form'
import UserDetailsModal from '@features/panels/components/video-call/user-details-modal'
import { BoxContainer } from './participant-title.styles'
import ReportUser from '@features/auth/components/report-user/report-user'
import { useToggle } from '@common/hooks'
import {
  useEventDisableVideoUserEventMutation,
  useEventEnableVideoUserEventMutation,
  useEventMuteEventMutation,
  useEventUnmuteEventMutation,
} from '@app/services'
import FollowUser from '@features/auth/components/follow-user'
import KickStudentModal from '@features/panels/components/kick-student-modal'
import PopoverMenu from './popover-menu'

export function ParticipantContextIfNeeded(
  props: React.PropsWithChildren<{
    participant?: Participant
  }>,
) {
  const hasContext = !!useMaybeParticipantContext()
  return props.participant && !hasContext ? (
    <ParticipantContext.Provider value={props.participant}>
      {props.children}
    </ParticipantContext.Provider>
  ) : (
    <>{props.children}</>
  )
}
export function TrackRefContextIfNeeded(
  props: React.PropsWithChildren<{
    trackRef?: TrackReferenceOrPlaceholder
  }>,
) {
  const hasContext = !!useMaybeTrackRefContext()
  return props.trackRef && !hasContext ? (
    <TrackRefContext.Provider value={props.trackRef}>{props.children}</TrackRefContext.Provider>
  ) : (
    <>{props.children}</>
  )
}

export interface ParticipantTileProps extends React.HTMLAttributes<HTMLDivElement> {
  trackRef?: TrackReferenceOrPlaceholder
  disableSpeakingIndicator?: boolean
  onParticipantClick?: (event: ParticipantClickEvent) => void
}

export const ParticipantTile: (
  props: ParticipantTileProps & React.RefAttributes<HTMLDivElement>,
) => React.ReactNode = React.forwardRef<HTMLDivElement, ParticipantTileProps>(
  function ParticipantTile(
    { trackRef, onParticipantClick, disableSpeakingIndicator, ...htmlProps }: ParticipantTileProps,
    ref,
  ) {
    const { mode } = useTheme()
    const { event: currentEvent, isHost } = useCurrentEvent()
    const trackReference = useEnsureTrackRef(trackRef)
    const isSpeaking = useIsSpeaking(trackReference.participant)
    const { elementProps } = useParticipantTile<HTMLDivElement>({
      htmlProps,
      disableSpeakingIndicator,
      onParticipantClick,
      trackRef: trackReference,
    })
    const layoutContext = useMaybeLayoutContext()

    const autoManageSubscription = useFeatureContext()?.autoSubscription

    const handleSubscribe = React.useCallback(
      (subscribed: boolean) => {
        if (
          trackReference.source &&
          !subscribed &&
          layoutContext &&
          layoutContext.pin.dispatch &&
          isTrackReferencePinned(trackReference, layoutContext.pin.state)
        ) {
          layoutContext.pin.dispatch({ msg: 'clear_pin' })
        }
      },
      [trackReference, layoutContext],
    )
    const remoteParticipants = useRemoteParticipants()
    return (
      <PopoverMenu
        trackReference={trackReference}
        muted={trackReference.participant.getTrackPublication(Track.Source.Microphone)?.isMuted}
        videoTrack={trackReference.participant.isCameraEnabled}
        id={trackReference.participant.identity}
      >
        <BoxContainer
          ref={ref}
          sx={{
            ...(isSpeaking && {
              outline: '4px solid green',
              transition: 'all 0.15s ease',
            }),
          }}
          {...elementProps}
        >
          <TrackRefContextIfNeeded trackRef={trackReference}>
            <ParticipantContextIfNeeded participant={trackReference.participant}>
              <>
                {trackReference.participant.identity === String(currentEvent?.host?.id) && (
                  <IconContainer>
                    <ShieldIcon sx={{ zIndex: '555', color: 'white', fontSize: '17px' }} />
                  </IconContainer>
                )}
                {isTrackReference(trackReference) &&
                (trackReference.publication?.kind === 'video' ||
                  trackReference.source === Track.Source.Camera ||
                  trackReference.source === Track.Source.ScreenShare) ? (
                  <VideoTrack
                    trackRef={trackReference}
                    onSubscriptionStatusChanged={handleSubscribe}
                    manageSubscription={autoManageSubscription}
                  />
                ) : (
                  isTrackReference(trackReference) && (
                    <AudioTrack
                      trackRef={trackReference}
                      onSubscriptionStatusChanged={handleSubscribe}
                    />
                  )
                )}
                <Box
                  className='lk-participant-placeholder'
                  style={{
                    padding: '10%',
                    backgroundColor: mode === 'dark' ? '#252627' : 'white',
                  }}
                >
                  <Avatar
                    src={handleGravatar(trackReference.participant.attributes.avatar)}
                    sx={{
                      height: { xs: 'auto', sm: '70%', md: 'auto' },
                      width: { xs: '50%', sm: 'auto', md: '50%' },
                      maxWidth: { xs: '110px', sm: '320px' },
                      maxHeight: { xs: '220px', sm: '320px' },
                      aspectRatio: '1/1',
                    }}
                  />
                </Box>
                <div className='lk-participant-metadata'>
                  <div>
                    {trackReference.source === Track.Source.Camera ? (
                      <>
                        <ParticipantName>
                          {!trackReference.participant.getTrackPublication(Track.Source.Microphone)
                            ?.isMuted && (
                            <AudioWaveform
                              participant={
                                trackReference.participant.getTrackPublication(
                                  Track.Source.Microphone,
                                )?.audioTrack
                              }
                            />
                          )}

                          <TrackMutedIndicator
                            trackRef={{
                              participant: trackReference.participant,
                              source: Track.Source.Microphone,
                            }}
                            show={'muted'}
                          />
                        </ParticipantName>
                      </>
                    ) : (
                      <>
                        <ParticipantName>
                          <ScreenShareIcon style={{ marginRight: '0.25rem' }} />
                        </ParticipantName>
                      </>
                    )}
                  </div>
                  <ConnectionQualityIndicator
                    className='lk-participant-metadata-item'
                    style={{ backgroundColor: 'transparent' }}
                  />
                </div>
              </>
              {/* {remoteParticipants.length > 0 && !isHost && (
                <FocusToggle trackRef={trackReference} style={{ backgroundColor: 'transparent' }} />
              )} */}
            </ParticipantContextIfNeeded>
          </TrackRefContextIfNeeded>
        </BoxContainer>
      </PopoverMenu>
    )
  },
)

export const ParticipantName: (
  props: ParticipantNameProps & React.RefAttributes<HTMLSpanElement>,
) => React.ReactNode = React.forwardRef<HTMLSpanElement, ParticipantNameProps>(
  function ParticipantName({ participant, ...props }: ParticipantNameProps, ref) {
    const p = useEnsureParticipant(participant)
    const isLocal = p.isLocal
    const [openLocal, setOpenLocal] = React.useState(false)
    const [openRemote, setOpenRemote] = React.useState(false)
    return (
      <>
        <NameContainer
          ref={ref}
          disableRipple
          variant='contained'
          color='neutral'
          onClick={isLocal ? () => setOpenLocal(true) : () => setOpenRemote(true)}
        >
          {props.children}
          <Typography> {p.name !== '' ? p.name : p.identity}</Typography>
          <KeyboardArrowDownIcon color='primary' />
        </NameContainer>
        <UpdateProfileModal open={openLocal} onClose={() => setOpenLocal(false)} />
        <UserDetailsModal
          open={openRemote}
          userId={p.identity}
          handleClose={() => setOpenRemote(false)}
        />
      </>
    )
  },
)

const UpdateProfileModal = (props: { open: boolean; onClose: () => void }) => {
  const { open, onClose } = props
  return (
    <Modal
      open={open}
      onClose={onClose}
      paperProps={{
        sx: { height: '90%', maxWidth: '550px', width: '100%', borderRadius: '10px', p: 4 },
      }}
    >
      <Box>
        <Typography sx={{ display: 'inline' }}>Profile information</Typography>
        <UpdateAccountForm />
      </Box>
    </Modal>
  )
}

const AudioWaveform = ({
  participant,
}: {
  participant: LocalAudioTrack | RemoteAudioTrack | TrackReferenceOrPlaceholder | undefined
}) => {
  const waveform = useAudioWaveform(participant, { barCount: 5 })

  return (
    <Stack
      direction='row'
      spacing={0.5}
      alignItems='center'
      justifyContent='center'
      sx={{
        mr: 1,
        height: '20px',
        zIndex: 1000,
        width: waveform.bars.length === 0 ? '0px' : '40px',
      }}
    >
      {waveform.bars.map((value, i) => (
        <Box
          key={i}
          sx={{
            height: `${Math.max(22, value * 100)}%`,
            backgroundColor: 'primary.main',
            opacity: value > 0.01 ? 0.8 : 0.3,
            transition: 'all 0.15s ease',
            borderRadius: '30px',
            // minHeight: '3px',
            width: '2.5px',
          }}
        />
      ))}
    </Stack>
  )
}
